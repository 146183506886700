.remote {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
}
.remoteMapControls {
    height: 60%;
}
.remoteSpecialControls {
    height: 40%;
    text-align: center;
}

.remote .zoom {
    text-align: center;
    padding: 20px;
    height: 15%;
}
.remote .zoom > button:first-child {
    margin-right: 10px;
}

.remote .position {
    text-align: center;
    height: 45%;
    padding: 20px;
}
.remote #fabTop, .remote #fabLeft, .remote #fabRight {
    margin-bottom: 10px;
}
.remote #fabLeft {
    margin-right: 20px;
}

.remote #fabTop > .MuiFab-label {
    transform: rotate(90deg);
    padding-left: 10px;
}
.remote #fabBottom > .MuiFab-label {
    transform: rotate(270deg);
    padding-left: 10px;
}