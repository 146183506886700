body {
  overflow: hidden;
}

.leaflet-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 0;
}

#header {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 100;

  user-select: none;
  pointer-events: none;
}
#header .logo {
  width: 65px;
}
#header .title {
  padding-top: 40px;
}
#header .title > :last-child {
  margin-top: -8px;
}

#toggleSounds {
  position: absolute;
  z-index: 1000;
  right: 320px;
  top: 30px;
}

#volume {
  position: absolute;
  top: 40px;
  right: 150px;
  z-index: 100;
  width: 150px;
}

#currentDate {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 100;
  text-align: center;

  user-select: none;
  pointer-events: none;
}
#currentDate > .time {
  margin-top: -8px;
}

#schedule {
  position: absolute;
  bottom: 100px;
  right: 20px;
  z-index: 100;
}
#schedule > .MuiPaper-root {
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
}
#schedule .scheduleList {
  width: 450px;
  max-height: 300px;
  overflow: auto;
  padding: 0 !important;
}
.scheduleList::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}

.presentationControls #schedule {
  bottom: 25px;
}
.presentationControls #schedule .scheduleList {
  overflow: hidden;
  max-height: 430px;
}

.scheduleList::-webkit-scrollbar {
  width: 6px;
}

.scheduleList::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #f0d723;
}
.scheduleList .MuiGrid-container {
  flex-wrap: nowrap;
}
.scheduleList .schedule-time {
  font-weight: bold;
}
.scheduleList .schedule-time .MuiListItemText-secondary	{
  color: green;
}
.scheduleList .schedule-time.delayed .MuiListItemText-secondary	{
  color: red;
}
.scheduleList .schedule-departure {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
}
.scheduleList .schedule-departure .MuiListItemText-primary img {
  height: 18px;
  display: inline-block;
  padding-right: 5px;
  vertical-align: middle;
}
.scheduleList .schedule-departure .MuiListItemText-primary span {
  display: inline-block;
  vertical-align: middle;
}
.scheduleList .schedule-departure .MuiListItemText-primary span:last-child {
  margin-left: 5px;
  text-overflow: ellipsis;
}
span.lineName {
  color: white;
  font-size: 13px;
  padding: 0 6px;
}
span.lineName.bus {
  background: #7d176b;
}
span.lineName.express {
  background: #7D8086;
}
span.lineName.regional {
  background: #E3001B;
}
span.lineName.ferry {
  background: #0080BA;
}
span.lineName.tram {
  background: #cc0a22;
}
span.lineName.subway {
  background: #004F8D;
}
span.lineName.suburban {
  background: #008D4F;
}
.scheduleList .schedule-departure .MuiListItemText-secondary {
  white-space: nowrap;
}

.scheduleList .schedule-departure .MuiListItemText-secondary::before {
  content: '\27F6';
  display: inline;
  position: relative;
  padding-right: 2px;
}



#fabSchedule {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 100;
}

#fabPlay {
  position: absolute;
  bottom: 24px;
  left: 160px;
  z-index: 100;
}


#fabZoomIn {
position: absolute;
top: 130px;
right: 20px;
z-index: 10000;
}
#fabZoomOut {
position: absolute;
top: 180px;
right: 20px;
z-index: 10000;
}


#fabSelectStations {
position: absolute;
bottom: 20px;
left: 90px;
z-index: 100;
}

#fabDemo {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 100;
}

#selectStations {
position: absolute;
bottom: 100px;
left: 20px;
z-index: 100;
}
#selectStations .kn-selection_status__clear_all___3gppf {
display: none;
}
#selectStations .kn-column__column___3Iwzx:last-of-type {
min-width: 250px;
}